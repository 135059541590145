interface Option {
    color?: string
    backgroundColor?: string,
    className?: string
}

export default function TermAndConcitionIcon(props: Option) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
            <g clip-path="url(#clip0_1047_2987)">
                <rect width="32" height="32" rx="16" fill={props.backgroundColor || '#F7C77C'}/>
                <path d="M19.7833 15.1666L16.8333 12.2166L18.0083 11.0416L19.775 12.8082L23.3083 9.2749L24.4833 10.4499L19.7833 15.1666ZM15.1667 11.8332H7.66667V13.4999H15.1667V11.8332ZM23.5 17.1749L22.325 15.9999L20.1667 18.1582L18.0083 15.9999L16.8333 17.1749L18.9917 19.3332L16.8333 21.4916L18.0083 22.6666L20.1667 20.5082L22.325 22.6666L23.5 21.4916L21.3417 19.3332L23.5 17.1749ZM15.1667 18.4999H7.66667V20.1666H15.1667V18.4999Z" fill={props.color || "#FFF5E6"}/>
            </g>
            <defs>
                <clipPath id="clip0_1047_2987">
                    <rect width="32" height="32" rx="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}