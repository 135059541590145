import { styled, Typography } from "@mui/material";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import AomdownApi from "../../../api/aomdown/aomdown.api";
import MasterDataApi from "../../../api/masterdata/masterdata.api";
import ButtonCustom from "../../../components/button/buttonCustom";
import Loading from "../../../components/loading/loading";
import { swalError } from "../../../components/notification/swal";
import CustomRadioBox from "../../../components/radioLabel/radioLabel";
import { colors } from "../../../constants/colors";
import TermAndConcitionIcon from "../../../assets/icon/termAndCondition";
import HeaderText from "../../../components/header/headerText";
import InputCheckbox from "../../../components/input/inputCheckbox";

const Container = styled("div")(() => ({
    width: "100%",
    height: "600px",
    border: `1px solid transparent`,
    borderRadius: 8,
    alignItems: "center",
    // display: "flex",
    flexDirection: "column",
    padding: 8,
    overflow: 'auto',
    backgroundColor: colors.lightGrayBG,
    '&::-webkit-scrollbar': {
        width: '4px',
    },
    '&::-webkit-scrollbar-track': {
        background: colors.lightGrayBG, // Background of the scrollbar track
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.themeMainColor, // Color of the scrollbar thumb
        borderRadius: '10px',
        border: `2px solid ${colors.themeMainColor}`, // Optional padding between thumb and track
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: colors.themeMainColor, // Hover state color
    },
}));

interface AccpteCondition {
    typeAomDown: string
    onClickNext?: () => void;
}

export default function AccpteCondition(props: AccpteCondition) {
    const [disable, setDisable] = useState(true);
    const [dataCondtion, setDataCondition] = useState('')
    const [radioAceept, setRadioAceept] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null);
    const [loadingPage, setLoadingPage] = useState(false);

    useEffect(() => {
        getTermsAndConditions();
    }, []);

    useEffect(() => {
        if (containerRef.current) {

            // Check if the container's height is enough to fit all content
            const containerHeight = containerRef.current.clientHeight;
            const isScrollable = containerRef.current.scrollHeight - containerRef.current.scrollTop == (containerHeight / 2)
            isScrollable ? setDisable(false) : setDisable(true);
        }
    }, [dataCondtion]); // Run this effect after dataCondtion is updated


    const handleScroll = (e: any) => {
        const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) == (e.target.clientHeight);
        // if (bottom) {
        setDisable(false)
        // }
    };

    const getTermsAndConditions = async () => {
        try {
            setLoadingPage(true);
            let type = props.typeAomDown || ''
            const res = await MasterDataApi.getTermsAndConditions(type);
            if (res.status == 200) {
                setLoadingPage(false)
                const termsAndConditions = res?.data?.data
                if (termsAndConditions) setDataCondition(termsAndConditions)

            } else {
                setLoadingPage(false)
                swalError(String(res?.data?.message));
            }
        } catch (error: any) {
            swalError(String('เกิดข้อผิดพลาดไม่สามารถทำรายการได้'));
            setLoadingPage(false)
            console.error(error)
        }
    }

    const updateAcceptConditions = async () => {
        try {
            setLoadingPage(true);
            const res = await AomdownApi.updateAcceptConditions();
            if (res.status == 200) {
                setLoadingPage(false);
                props.onClickNext && props.onClickNext();
            }
            props.onClickNext && props.onClickNext();
        } catch (error) {
            setLoadingPage(false);
            console.error(error)
        }
    }

    return (
        <div className="px-3 py-3">
            <Loading show={loadingPage} />
            <div className="pb-3">
                <HeaderText label="ข้อกำหนดและเงื่อนไข" component={<TermAndConcitionIcon />} enableBorder={false} />
            </div>
            <Container ref={containerRef} onScroll={handleScroll}>
                {_.map(dataCondtion, (val: any) => {
                    return (
                        <div className="py-2">
                            <span className="font-bold pr-1">{val?.no || ''}</span>
                            <span>{val?.text || ''}</span>
                        </div>
                    )
                })}
            </Container>
            <div className="pt-2">
                <InputCheckbox
                    disabled={disable}
                    label={'ฉันยอมรับข้อกำหนดและเงื่อนไข'}
                    checked={radioAceept}
                    onChange={(e) => {
                        setRadioAceept(e.target.checked);
                    }} />

            </div>
            <div className="pt-4">
                <ButtonCustom
                    mode={'default'}
                    btnStyle={{ width: '100%' }}
                    textButton={'ถัดไป'}
                    disabled={disable || !radioAceept}
                    onClick={() => {
                        updateAcceptConditions();
                    }}
                />
            </div>
        </div>
    )
}