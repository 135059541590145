

interface Option {
   readonly bgColor?: string
   readonly iconColor?:string
}

export default function ReceiptIcon(props: Option) {
    return (
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="80" height="80" rx="40" fill={props.bgColor ?? "#E3E3E3"} />
            <path d="M24.7857 28.2147C24.7857 27.1728 25.1996 26.1735 25.9364 25.4368C26.6731 24.7 27.6724 24.2861 28.7143 24.2861H45.1428C45.6588 24.2861 46.1696 24.3877 46.6462 24.5852C47.1229 24.7826 47.556 25.072 47.9208 25.4368C48.2856 25.8016 48.5749 26.2347 48.7724 26.7113C48.9698 27.1879 49.0714 27.6988 49.0714 28.2147V44.2861H56.2143V50.3576C56.2143 51.7784 55.6499 53.141 54.6452 54.1456C53.6405 55.1503 52.2779 55.7147 50.8571 55.7147H30.1428C28.722 55.7147 27.3594 55.1503 26.3548 54.1456C25.3501 53.141 24.7857 51.7784 24.7857 50.3576V28.2147ZM49.0714 53.5718H50.8571C51.7096 53.5718 52.5272 53.2332 53.13 52.6304C53.7328 52.0276 54.0714 51.21 54.0714 50.3576V46.429H49.0714V53.5718ZM30.5 32.5004C30.5 33.0918 30.98 33.5718 31.5714 33.5718H42.2857C42.5699 33.5718 42.8424 33.459 43.0433 33.258C43.2443 33.0571 43.3571 32.7846 43.3571 32.5004C43.3571 32.2163 43.2443 31.9437 43.0433 31.7428C42.8424 31.5419 42.5699 31.429 42.2857 31.429H31.5714C31.2873 31.429 31.0147 31.5419 30.8138 31.7428C30.6129 31.9437 30.5 32.2163 30.5 32.5004ZM31.5714 38.5718C31.2873 38.5718 31.0147 38.6847 30.8138 38.8857C30.6129 39.0866 30.5 39.3591 30.5 39.6433C30.5 39.9274 30.6129 40.2 30.8138 40.4009C31.0147 40.6018 31.2873 40.7147 31.5714 40.7147H42.2857C42.5699 40.7147 42.8424 40.6018 43.0433 40.4009C43.2443 40.2 43.3571 39.9274 43.3571 39.6433C43.3571 39.3591 43.2443 39.0866 43.0433 38.8857C42.8424 38.6847 42.5699 38.5718 42.2857 38.5718H31.5714ZM30.5 46.7861C30.5 47.3776 30.98 47.8576 31.5714 47.8576H36.5714C36.8556 47.8576 37.1281 47.7447 37.329 47.5437C37.53 47.3428 37.6428 47.0703 37.6428 46.7861C37.6428 46.502 37.53 46.2295 37.329 46.0285C37.1281 45.8276 36.8556 45.7147 36.5714 45.7147H31.5714C31.2873 45.7147 31.0147 45.8276 30.8138 46.0285C30.6129 46.2295 30.5 46.502 30.5 46.7861Z" fill={props.iconColor ?? "#ACACAC"} />
            <path d="M54.5 51C52.6505 51.0224 50.883 51.7671 49.5751 53.0751C48.2671 54.383 47.5224 56.1505 47.5 58C47.5224 59.8495 48.2671 61.617 49.5751 62.9249C50.883 64.2329 52.6505 64.9776 54.5 65C56.3495 64.9776 58.117 64.2329 59.4249 62.9249C60.7329 61.617 61.4776 59.8495 61.5 58C61.4776 56.1505 60.7329 54.383 59.4249 53.0751C58.117 51.7671 56.3495 51.0224 54.5 51ZM58.5 58.5H55V62H54V58.5H50.5V57.5H54V54H55V57.5H58.5V58.5Z" fill="#ACACAC" />
        </svg>

    )
}