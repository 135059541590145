import liff from "@line/liff";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AuthenApi from '../../api/authen/authen.api';
import ButtonCustom from "../../components/button/buttonCustom";
import { swalError, swalSuccess, swalWarning } from "../../components/notification/swal";
import UploadSlips from "../../components/upload/uploadSlip";
import { colors } from "../../constants/colors";
import { imgs } from "../../constants/images";
import * as Styles from "./upload-slip.style";
import { routeName } from "../../routes/routers-name";
import MasterDataApi from "../../api/masterdata/masterdata.api";
import PaymentUploadApi from "../../api/payment/payment-upload.api";
import StorageUploadApi from "../../api/storage/storage.api";
import ReceiptIcon from "../../assets/icon/receipt";
import Loading from "../../components/loading/loading";
import AomdownApi from "../../api/aomdown/aomdown.api";

interface uploadSlip {
  onClickBack?: () => void;
  onClickNext?: () => void;
}

export default function UploadSlip(props: uploadSlip) {
  const [firstName, setFirstName] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNameTh, setAccountNameTh] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const [file, setFile] = useState<any>();
  const [loadingImg, setLoadingImg] = useState<boolean>(false);
  const [activeBtn, setActiveBtn] = useState<boolean>(true);
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledUploadSlip, setDisabledUploadSip] = useState<boolean>(false)

  const loadCurrentUser = async () => {
    try {
      const result = await AuthenApi.getUser();
      if (result.status === 200) {
        setFirstName(result?.data?.data?.getCurrent?.fullName);
      }
      if (result.data.errors) {
        swalWarning(
          "อัพโหลดสลิป",
          "ระบบไม่พบข้อมูลผู้ใช้ กรุณาเข้าสู่ระบบก่อนค่ะ",
          closePage
        )
      }
    } catch (error) {
      swalError("loadCurrentUser : " + error);
    }
  }

  const onFetchValidateUpload = async () => {
    setLoading(true)
    try {
      const res = await AomdownApi.getValidateUploadSlip();
      if (res.status === 200) {
        setDisabledUploadSip(res.data?.data?.validatePaymentAmount || false)
      }
    } catch (error) {
      swalError("onFetchValidateUpload : " + error);
    } finally {
      setLoading(false)
    }
  }

  const getBankDetail = async () => {
    const bankCode = (process.env.BANK_CODE ?? "004")
    const bankDetail = await MasterDataApi.getBankDetail(bankCode)
    if (bankDetail.status === 200) {
      setBankName(bankDetail?.data?.data?.bankName)
      setAccountNameTh(bankDetail?.data?.data?.accountNameTh)
      setAccountNumber(bankDetail?.data?.data?.accountNumber)
    }
  }

  useEffect(() => {
    loadCurrentUser();
    getBankDetail();
    onFetchValidateUpload()
  }, []);

  const handleCopy = (accountNumber: any) => {
    setIsCopy(true);
    navigator.clipboard.writeText(accountNumber.replace(/-/g, ""));
    setTimeout(() => {
      setIsCopy(false);
    }, 2000);
  };

  const onUpload = (event: any) => {
    if (event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }

    if (event.target.files[0]) {
      setFile(event.target.files[0]);
      setActiveBtn(false);
    }
  };

  const closePage = async () => {
    liff.closeWindow();
  }
  const onsubmit = async () => {

    if (image !== "") {
      setActiveBtn(true);
      setLoadingImg(true);
      setLoadingPage(true)
      try {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("bucket", 'contract-down/slip-down');
        // formData.append("bucket", '2');
        const res = await StorageUploadApi.uploadSlipFile(formData)
        if (res.status === 200) {
          const result = await PaymentUploadApi.paymentUploadSlipFile({ slip: res.data?.url[0]?.url || "" })
          if (result.status === 200) {
            setActiveBtn(false);
            setImage("");
            setLoadingImg(false);
            setLoadingPage(false)
            swalSuccess(
              "อัพโหลดสลิปเรียบร้อย",
              '',
              'แอดมินจะทำการตรวจสอบและยืนยันสลิปอีกครั้ง',
              null,
              closePage
            );
          } else {
            swalError("ระบบมีปัญหากรุณาลองใหม่อีกครั้งค่ะ");
            setActiveBtn(true);
          }
        } else {
          swalError("ระบบมีปัญหากรุณาลองใหม่อีกครั้งค่ะ");
          setActiveBtn(true);
        }

      } catch (error: any) {
        console.error("Error upload slip : ", error);
        setLoadingImg(false);
        setLoadingPage(false)
        swalError("กรุณาอัพโหลดสลิป ขนาดรูปภาพไม่เกิน 2MB");
        setActiveBtn(false);
      }
    } else {
      swalError("กรุณาเลือกรูปภาพ!");
    }
  };


  return (
    <div>
      <Loading show={loadingPage || loading}></Loading>
      <div className="container pt-3 px-3 py-2">
        {/* Text Container */}
        <div className="pt-2 d-flex flex-column align-items-center" style={{ maxWidth: 700, margin: '0 auto' }}>
          <div className="d-flex align-items-center">
            <Typography fontSize={14} style={{ color: colors.black }}>
              สวัสดีคุณ
            </Typography>
            <Typography className="fw-bold" fontSize={14} style={{ marginLeft: "2px" }}>
              {firstName}
            </Typography>
          </div>
          <Typography fontSize={14} style={{ marginLeft: "2px", color: colors.danger }}>
            *กรุณาชำระเงินยอดขั้นต่ำ 50 บาท
          </Typography>
        </div>


        {/* Snippet */}
        <div className="pt-2">
          <Styles.Snippet bgcolor={colors.bgModal} mb={4} mt={2} maxWidth={700} mx={"auto"}>
            <div className="d-flex justify-content-start">
              <div style={{ marginTop: "5px" }}>
                <img className="logo-upload-slip" src={imgs.logoKbank} width={"100%"} />
              </div>

              <div style={{ marginLeft: "13px", fontSize: "14px" }}>
                <Typography>{bankName}</Typography>
                <Typography>ชื่อบัญชี {accountNameTh}</Typography>

                <div className="d-flex align-items-center">
                  <Typography className="fw-bold" style={{ color: colors.themeThirdColor, marginRight: '8px' }}>
                    {accountNumber}
                  </Typography>

                </div>



              </div>
            </div>
            <div className="d-flex justify-content-end mt-2">
              <ButtonCustom
                mode={"default"}
                btnStyle={{ width: 'auto', height: 30 }}
                textButton="คัดลอกเลขบัญชี"
                variant={'outlined'}
                onClick={() => {
                  handleCopy(accountNumber);
                }}
              />
            </div>
          </Styles.Snippet>
          {/* 
          <div className="d-flex flex-column align-items-start" style={{ maxWidth: 700, margin: '0 auto' }}>
            <Typography className="fw-bold" fontSize={"20px"}>
              อัพโหลดสลิป
            </Typography>
          </div>
          <div className="d-flex flex-column align-items-end">
          <ButtonCustom
                                mode={'default'}
                                btnStyle={{ width: '50%', height: 40, borderRadius: 8 }}
                                // endIcon={<Download />}
                                textButton={'ประวัติการชำระเงิน'}
                                // onClick={() => {
                                //     if (data?.downpayment_no) getAomdownFileDownload(data?.downpayment_no)
                                // }}
                            />
          </div> */}
          <div className="d-flex flex-row justify-content-between align-items-center" style={{ maxWidth: 700, margin: '0 auto' }}>
            <Typography className="fw-bold" fontSize={"20px"}>
              อัพโหลดสลิป
            </Typography>
            <ButtonCustom
              mode={'default'}
              btnStyle={{ width: '100%', height: 40, borderRadius: 8 }}
              textButton={'ประวัติการชำระเงิน'}
              // endIcon={<Download />}
              onClick={() => {
                if (props.onClickNext) {
                  props.onClickNext()
                }
              }
              }
            />
          </div>


          <Styles.Snippet width={"auto"} height={381} maxWidth={700} mx={"auto"} bgcolor={colors.bgModal} mb={4} mt={2} className="text-center">
            {!disabledUploadSlip ? <UploadSlips
              imageIconSvg={ReceiptIcon}
              preview={image}
              loading={loadingImg}
              helperText={"กรุณาอัพโหลดไฟล์รูปภาพเท่านั้น"}
              onUpload={(event: any) => {
                onUpload(event);
              }}
            />
              : 
              <div style={{ marginTop: '45%' }}>
                <div style={{ color: colors.black }}>
                  สามารถกดติดต่อรับเครื่องได้เลย
                </div>
                <div style={{ color: colors.black }}>
                  ที่หน้าข้อมูลสัญญา
                </div>
              </div>
              }
          </Styles.Snippet>
          <div className="pt-2 d-flex flex-column align-items-start" style={{ maxWidth: 700, margin: '0 auto' }}>
            {!disabledUploadSlip && <ButtonCustom
              mode={"default"}
              textButton="ยืนยันการอัพโหลดสลิป"
              onClick={() => {
                onsubmit();
              }}
              style={{ width: "100%" }}
              disabled={activeBtn}
            /> }
          </div>
        </div>
      </div>
    </div>

  );


}
