import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AomdownApi from "../../../api/aomdown/aomdown.api";
import PersonalDetailApi from "../../../api/personal-detail/personalDetail.api";
import Saving from "../../../assets/icon/piggy";
import ButtonCustom from "../../../components/button/buttonCustom";
import HeaderText from "../../../components/header/headerText";
import InputTextField from "../../../components/input/inputTextField";
import Loading from "../../../components/loading/loading";
import { swalError } from "../../../components/notification/swal";
import { changeLastname, checkEngLang, checkThaiLang, chkDigitPid, idCardFormat, numberFormat } from "../../../utils/common";
import { decryptString, encryptString } from "../../../utils/crypto-js";
import AuthenApi from "../../../api/authen/authen.api";

interface PersonalDetail {
  userDetail: any,
  onClickNext?: () => void;
}

const initStateErrorMessage = {
  NAME: ``,
  SURNAME: ``,
  IDCARD: ``,
};

// const ImageStyle = styled(`img`)(({ theme }) => ({
//   width: 180,
//   height: 180,

//   [theme.breakpoints.down("lg")]: {
//     width: 150,
//     height: 150,
//   },
// }));

export default function PersonalDetail(props: PersonalDetail) {
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [idCard, setIdCard] = useState<string>("");
  const [promotion, setPromotion] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage);
  const [loadingPage, setLoadingPage] = useState(false);
  const [checkIdCard, setCheckIdCard] = useState<boolean>(false)
  const [disabledField, setDisabledField] = useState<boolean>(false)

  const [adminRegister, setAdminRegister] = useState(false);
  const [data, setData] = useState<any>()

  useEffect(() => {
    if (idCard && idCard.length === 16) {
      const check = chkDigitPid(idCard.replace(/-/g, ""))
      if (!check) {
        setCheckIdCard(true)
        const msg: any = {
          IDCARDd: !check ? 'กรอกรหัสบัตรประชาชนไม่ถูกต้อง' : '',
        }
        setErrorMessage({ ...errorMessage, ...msg })
      } else {
        setCheckIdCard(false)
      }
    }
  }, [idCard]);

  const handleNextStep = async () => {
    const handleErrorMessage = {
      NAME: !checkThaiLang(name) ? "โปรดกรอกชื่อ (ภาษาไทย)" : "",
      SURNAME: !checkThaiLang(surname) ? "โปรดกรอกนามสกุล (ภาษาไทย)" : "",
      IDCARD: !idCard ? 'โปรดกรอกรหัสบัตรประชาชน' : idCard.length < 16 ? 'โปรดกรอกรหัสบัตรประชาชนให้ครบ 13 หลัก' : checkIdCard ? 'กรอกรหัสบัตรประชาชนไม่ถูกต้อง' : '',
    };
    setErrorMessage({ ...initStateErrorMessage, ...handleErrorMessage });

    if (!handleErrorMessage.IDCARD && !handleErrorMessage.NAME && !handleErrorMessage.SURNAME) {
      try {
        setLoadingPage(true);
        const lastName = changeLastname(surname);
        const payload = {
          name: encryptString(name.trim()),
          surname: encryptString(lastName),
          idCard: encryptString(idCard.trim().replace(/-/g, "")),
          promoCode: promotion.trim(),
        };
        const res = await PersonalDetailApi.updatePersonalDetail(payload);
        if (res.status === 200) {
          setLoadingPage(false);
          props.onClickNext && props.onClickNext();
        } else {
          setLoadingPage(false)
          swalError(String(res?.data?.message));
        }
        props.onClickNext && props.onClickNext();
      } catch (error: any) {
        swalError(String(error.response.data.data));
        setLoadingPage(false);
      }
    }
  };

  const checkAdminRegister = async () => {
    setLoadingPage(true);
    const res = await AomdownApi.getAdminRegister();
    if (res.status === 200) {
      const data = res.data.data;
      if (data) {
        setAdminRegister(true)
        const fdata = {
          name: decryptString(data?.user_info?.general?.fullname),
          idCard: decryptString(data?.id_card),
          product_name: data?.product?.text,
          round_payment: data?.downpayment_info?.round_payment,
          time_payment: data?.downpayment_info?.time_payment,
          round_amount: data?.downpayment_info?.round_amount,
          product_img: data?.product_img
        }
        setData(fdata)
      }
      // else {
      //   if (props?.userDetail && JSON.stringify(props?.userDetail !== '{}')) {
      //     const detail = props?.userDetail
      //     console.log('detail :>> ', detail);
      //     setName(decryptString(detail?.firstName || '') || "")
      //     setSurname(decryptString(detail?.lastName || '') || "")
      //     setIdCard(decryptString(detail?.idCard || '') || "")
      //     window.location.reload();
      //   } 
      // }
      setLoadingPage(false);
    } else {
      setLoadingPage(false);
    }
  };

  const formatRoundPayment = (key: string) => {
    switch (key) {
      case 'DAY':
        return 'รายวัน'
      case 'WEEK':
        return 'รายสัปดาห์'
      case 'MONTH':
        return 'รายเดือน'
      default:
        break;
    }
  }

  const loadCurrentUser = async () => {
    const result = await AuthenApi.getUser();
    if (result.status === 200 && result?.data?.data) {
      if (result?.data?.data?.getCurrent.fullName !== "") {
        const [firstName, lastName] = result?.data?.data?.getCurrent.fullName?.split(" ") ?? "";
        setName(firstName)
        setSurname(lastName)
        setIdCard(idCardFormat(result?.data?.data?.getCurrent?.code));
        setDisabledField(true)
      }
    }
  }

  useEffect(() => {
    checkAdminRegister();
    loadCurrentUser()
  }, [])

  return (
    <div className="px-3 py-3">
      <Loading show={loadingPage} />
      <HeaderText label="ลงทะเบียนผ่อน" component={<Saving />} enableBorder={true} />
      {(adminRegister) ? (
        <div>
          <div className="pt-2">
            <InputTextField
              disabled
              value={`${data?.name}`}
              heading="ชื่อ-นามสกุล"
            />
          </div>
          <div className="pt-2">
            <InputTextField
              disabled
              value={idCardFormat(data?.idCard)}
              heading="เลขบัตรประชาชน"
            />
          </div>
          <div className="pt-2">
            <InputTextField
              disabled
              value={data?.product_name}
              heading="สินค้า"
            />
          </div>
          <div className="pt-2">
            <Row>
              <Col>
                <InputTextField
                  disabled
                  value={formatRoundPayment(data?.round_payment)}
                  heading="รอบการผ่อน"
                />
              </Col>
              <Col>
                <InputTextField
                  disabled
                  value={data?.time_payment}
                  heading="ระยะเวลาผ่อน (เดือน)"
                />
              </Col>
            </Row>
          </div>
          <div className="pt-2">
            <InputTextField
              disabled
              value={numberFormat(data?.round_amount, 0, 0)}
              heading="ยอดผ่อนต่อรอบ"
            />
          </div>
          {/* <div className="text-center pt-4">
            <ImageStyle src={data?.product_img} onError={onImgError} />
          </div> */}
          <div className="pt-4">
            <ButtonCustom
              mode={"default"}
              btnStyle={{ width: "100%" }}
              textButton={"ถัดไป"}
              onClick={() => {
                props.onClickNext && props.onClickNext();
              }}
            />
          </div>

        </div>) : (<div>
          <div className="pt-2">
            <InputTextField
              value={name}
              heading="ชื่อจริง (ภาษาไทย)"
              onchange={(e: any) => {
                if (checkThaiLang(e.target.value)) {
                  setName(e.target.value);
                  setErrorMessage({ ...errorMessage, NAME: "" });
                } else if (e.target.value === '') {
                  setName('')
                }

              }}
              helperText={errorMessage.NAME}
              required
              placeholder="กรอกชื่อจริง (ภาษาไทย)"
              disabled={disabledField}
            />
          </div>
          <div className="pt-2">
            <InputTextField
              value={surname}
              heading="นามสกุล (ภาษาไทย)"
              onchange={(e: any) => {
                if (checkThaiLang(e.target.value)) {
                  setSurname(e.target.value);
                  setErrorMessage({ ...errorMessage, SURNAME: "" });
                } else if (e.target.value === '') {
                  setSurname('')
                }

              }}
              helperText={errorMessage.SURNAME}
              required
              placeholder="กรอกนามสกุล (ภาษาไทย)"
              disabled={disabledField}
            />
          </div>
          <div className="pt-2">
            <InputTextField
              value={idCardFormat(idCard)}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              heading="เลขบัตรประชาชน"
              onchange={(e: any) => {
                if (e.target.value.length <= 16) {
                  setIdCard(e.target.value);
                  setErrorMessage({ ...errorMessage, IDCARD: "" });
                }

              }}
              helperText={errorMessage.IDCARD}
              required
              placeholder="กรอกรหัสบัตรประชาชน"
              disabled={disabledField}
            />
          </div>
          <div className="pt-2">
            <InputTextField
              value={promotion}
              heading="รหัสโปรโมชั่น (ถ้ามี)"
              onchange={(e: any) => {
                if (checkEngLang(e.target.value) && e.target.value.length <= 15) {
                  setPromotion(e.target.value);
                } else if (e.target.value === '') {
                  setPromotion('')
                }
              }}
              placeholder="กรอกรหัสโปรโมชัน"
            />
          </div>
          <div className="pt-4">
            <ButtonCustom
              mode={"default"}
              btnStyle={{ width: "100%" }}
              disabled={name === "" || surname === "" || idCard === ""}
              textButton={adminRegister ? "ถัดไป" : "ยืนยันลงทะเบียน"}
              onClick={() => {
                handleNextStep();
              }}
            />
          </div>
        </div>)}
    </div>
  );
}
