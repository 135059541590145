import { useEffect, useState } from "react";
import UploadSlip from "./upload-slip";
import PayHistory from "./pay-history";
import liff from "@line/liff";

export default function PaymentPage() {
    const [stepper, setStepper] = useState<number>(0);

    useEffect(() => {
        // liff.init({ liffId: '2006354132-Rpne3eZD', withLoginOnExternalBrowser: true })
        liff.init({ liffId: `${process.env.REACT_APP_LIFF_ID_UPLOAD_SLIP}`, withLoginOnExternalBrowser: true })
            .then(async () => {
                if (liff.isLoggedIn()) {
                    return true
                } else {
                    liff.login();
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    return (
        <div>
            {stepper < 1 && <div>
                <UploadSlip
                    key="UploadSlip"
                    onClickNext={() => setStepper(1)}
                    onClickBack={() => {
                        setStepper(0);
                    }}
                />
            </div>}

            {stepper === 1 &&
                <div>
                    <PayHistory
                        key="PayHistory"
                        onClickBack={() => {
                            setStepper(0);
                        }}
                    />
                </div>
            }

        </div>
    )
}