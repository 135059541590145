import BaseAPI from '../api'

const path = '/aomdown/app/masterdata'
// const path = '/app/masterdata'

export default class MasterDataApi extends BaseAPI {

  static getTermsAndConditions(type: string): Promise<any> {
    return this.api.get(`${path}/getTermsAndConditons`, { params: { type } }).then((res) => res)
  }

  static getProduct(param: any): Promise<any> {
    return this.api.get(`${path}/product`, { params: { ...param } }).then((res) => res)
  }

  static getProductDetail(param: any): Promise<any> {
    return this.api.get(`${path}/product-detail`, { params: { ...param } }).then((res) => res)
  }

  static getBankDetail(bankCode: any): Promise<any> {
    return this.api.get(`${path}/getBankDetail`, { params: { bankCode } }).then((res) => res)
  }

  static getBanner(): Promise<any> {
    return this.api.get(`${path}/getBanner`).then((res) => res)
  }

}
