import BaseAPIUpload from '../api-upload'

const path = '/storage'

export default class StorageUploadApi extends BaseAPIUpload {

    static uploadSlipFile(payload: any): Promise<any> {
        return this.api.post(`${path}/file/customer/upload`, payload).then((res) => res)
    }

    static uploadFile(payload: any): Promise<any> {
        return this.api.post(`${path}/file/customer/upload`, payload).then((res) => res)
    }

}