import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Row } from "react-bootstrap";
import { colors } from "../../constants/colors";

export const Container = styled(`div`)(({ theme }) => ({
  padding: "20px 150px",
  textAlign: "center",
  marginTop: 30,
  marginBottom: 30,

  [theme.breakpoints.down("lg")]: {
    padding: "10px 50px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "10px 8px",
  },
}));

export const Snippet = styled(Box)(() => ({
  padding: "20px",
  borderRadius: 10,
  position: "relative",
}));

export const MarginRow = styled(Row)(() => ({
  marginTop: 8,
  textAlign: "center",
}));

export const CenterContainer = styled(Row)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ContactBox = styled(Box)(() => ({
  padding: "20px",
  textAlign: "center",
  border: `1px solid ${colors.black}`,
  borderRadius: 20,
  position: "relative",
  maxWidth: 450,
}));
