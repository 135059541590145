import { styled, ToggleButtonGroup } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AomdownApi from "../../../api/aomdown/aomdown.api";
import GetPaymenApi from "../../../api/card-product-detail/card-product-detail-api";
import MasterDataApi from "../../../api/masterdata/masterdata.api";
import ButtonCustom from "../../../components/button/buttonCustom";
import InputTextField from "../../../components/input/inputTextField";
import { swalError } from "../../../components/notification/swal";
import AutocompleteSelect from "../../../components/select/autoCompleteSelect";
import { colors } from "../../../constants/colors";
import { onImgError } from "../../../constants/images";
import { numberFormat } from "../../../utils/common";
import debounce from 'lodash/debounce';

interface productDetail {
  code?: string;
  onClickBack?: () => void;
  onClickNext?: (e: any) => void;
}

const DotSelect = styled("div")(({ theme }) => ({
  zIndex: 1,
  position: "absolute",
  height: 32,
  width: 32,
  borderRadius: "50%",
  display: "inline-block",
  margin: -1,
}));

const DotSelectMini = styled("div")(({ theme }) => ({
  zIndex: 1,
  position: "absolute",
  height: 6,
  width: 6,
  borderRadius: "50%",
  display: "inline-block",
  transform: 'translate(12px , -13px)',
  transition: 'background-color 0.3s'
}));

const Dot = styled("div")(({ theme }) => ({
  zIndex: 10,
  position: "relative",
  height: 30,
  width: 30,
  borderRadius: "50%",
  display: "inline-block",
  boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.6)",
}));

const SlashDot = styled("div")(({ theme }) => ({
  zIndex: 10,
  position: "relative",
  height: 30,
  width: 30,
  borderRadius: "50%",
  display: "inline-block",
  boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.6)",

  // Add a slash overlay
  "&:after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(130deg)",
    width: "90%",
    height: "2px",
    backgroundColor: colors.themeMainColor,
  }
}));

const initStateMessaageError = {
  ROUND: ``,
  TIME: ``,
  ERROR: ``,
  MONTH: ``,
}

export default function CardProductDetail(props: productDetail) {
  const [data, setData] = useState<any>([]);
  const [selectedStorage, setSelectedStorage] = useState<string>("");
  const [selectColor, setSelectColor] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [down, setDown] = useState<string>("");
  const [price2, setPrice2] = useState<number>(0)
  const [image, setImage] = useState<string>("");
  const [dataRoundPayment, setDataRoundPayment] = useState<any>([]);
  const [selectRoundPayment, setSelectRoundPayment] = useState<string>("");
  const [selectTimePayment, setSelectTimePayment] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState(initStateMessaageError)
  const [amountPerRound, setAmountPerRound] = useState<string>('');
  // const [amountPerRoundDefault, setAmountPerRoundDefault] = useState<string>('');
  const [typeAomDown, setTypeAomDown] = useState<string>('INSTALLMENT_AND_USE')
  const [totalMonth, setTotalMonth] = useState<number>(0)
  // const [minPayment, setMinPayment] = useState<number>(0)
  const [disabledSave, setDisableSave] = useState<boolean>(true)

  const loadProductDetail = useCallback(() => {
    try {
      let condition: any = {};
      if (props.code) condition = { ...condition, code: props.code };
      MasterDataApi.getProductDetail(condition).then((res: any) => {
        if (res.status === 200) {
          const val = res.data?.data?.result;
          setData(val);
          setSelectedStorage(val[0]?.option[0]?.storage || "");
          setSelectColor(val[0]?.option[0]?.color[0]?.colorName || "");
          setPrice(val[0]?.option[0]?.color[0]?.price || "");
          setDown(val[0]?.option[0]?.color[0]?.down || "");
          setPrice2(val[0]?.option[0]?.color[0]?.price2 || "")
          setImage(val[0]?.option[0]?.color[0]?.image || "");
        }
      });
    } catch (error) {
      swalError('product detail ' + error);
    }
  }, [props.code]);

  const loadRoundPayment = useCallback(async () => {
    try {
      const result = await GetPaymenApi.Round();
      if (result.status === 200) {
        const dataMap = await result.data.data.round_payment_data.map(
          (item: any) => {
            return {
              value: item.code,
              label: item.name,
            };
          }
        );
        setDataRoundPayment(dataMap);
      }
    } catch (error) {
      swalError('round payment ' + error);
    }
  }, []);

  useEffect(() => {
    loadRoundPayment();
  }, [loadRoundPayment]);

  useEffect(() => {
    loadProductDetail();
  }, [loadProductDetail]);


  //!ส่งค่าไปคำนวณเมื่อมีค่าเปลี่ยนแปลง
  useEffect(() => {
    const maxPrice = typeAomDown === 'INSTALLMENT_AND_USE' ? price : price2;
    const errorMessageText = 'ยอดผ่อนเกินราคาสินค้า';
    // ตรวจสอบว่า amountPerRound เกินราคาสูงสุดหรือไม่
    if (Number(amountPerRound) > Number(maxPrice)) {
      setErrorMessage(prev => ({
        ...prev,
        ERROR: errorMessageText,
      }));
    } else {
      setErrorMessage(prev => ({
        ...prev,
        ERROR: '',
      }));
    }
  }, [typeAomDown, price, price2]);

  const handelCalculate = async (amountRound: number, roundPayment: string, defaultMin: boolean) => {
    try {
      const maxPrice = typeAomDown === 'INSTALLMENT_AND_USE' ? price : price2;
      const errorMessageText = 'ยอดผ่อนเกินราคาสินค้า';
      setAmountPerRound(String(amountRound))
      if (Number(amountRound) > Number(maxPrice)) {
        setErrorMessage(prev => ({
          ...prev,
          ERROR: errorMessageText,
        }));
        return;
      } else {
        setErrorMessage(prev => ({
          ...prev,
          ERROR: '',
        }));
      }
      const payload = {
        totalAmount: typeAomDown === 'INSTALLMENT_AND_USE' ? Number(down) : Number(price2),
        paymentPerPeriod: amountRound,
        periodType: roundPayment
      };
      const result = await AomdownApi.calculateAomdown(payload);
      if (result.status === 200) {
        setTotalMonth(result.data.data.totalPeriods ?? result.data?.data?.MONTH);

        if (defaultMin) {
          setAmountPerRound(result.data?.data?.paymentPerPeriod)
        }
        setDisableSave(false)
        if (payload?.paymentPerPeriod === 0 && !defaultMin) {
          setErrorMessage({
            ROUND: '',
            TIME: '',
            ERROR: Number(amountRound) >= result.data?.data?.minPayment ? '' : `ยอดผ่อนรายสัปดาห์ต้องไม่ต่ำกว่า ${result.data?.data?.minPayment} บาท` || '',
            MONTH: ''
          });
          setDisableSave(true)
        } 

        if (result.data?.data?.ERROR) {
          setErrorMessage({
            ROUND: '',
            TIME: '',
            ERROR: Number(amountRound) >= result.data?.data?.VALUE ? '' : result.data?.data?.ERROR ?? '',
            MONTH: result.data?.data?.MONTH
          });
          setDisableSave(true)
        }
      }
    } catch (error) {
      setDisableSave(true)
      swalError("ระบบเกิดข้อผิดพลาด" + error);
    } finally {
    
    }
  };

  const handelSubmit = () => {
    if (selectRoundPayment === '') {
      const msg: any = {
        ROUND: selectRoundPayment === '' ? 'โปรดเลือกรายการผ่อน' : '',
        TIME: selectTimePayment === 0 ? 'โปรดเลือกระยะเวลาการผ่อน (เดือน)' : ''
      }
      return setErrorMessage({ ...initStateMessaageError, ...msg })
    }

    const selectData: any = {
      model: data[0]?.pname, // object ของ product
      storage: selectedStorage, // object ของ product
      color: selectColor, // object ของ product
      amount: (typeAomDown === 'INSTALLMENT_AND_USE' ? Number(down) : price2) || 0, // object ของ downpayment_info
      price: (typeAomDown === 'INSTALLMENT_AND_USE' ? Number(price) : price2) || 0, // object ของ downpayment_info
      time_payment: totalMonth, // object ของ downpayment_info
      round_payment: selectRoundPayment, // object ของ downpayment_info
      round_amount: amountPerRound, // object ของ downpayment_info
      type_aomdown: typeAomDown,
    };
    props.onClickNext && props.onClickNext(selectData); // ส่งค่ากลับไปยังต้นทาง พร้อมเปลี่ยน step
  };

  const changeStorage = (e: any) => {
    setSelectedStorage(e.storage);
    setSelectColor(e.color[0]?.colorName || "");
    setPrice(e.color[0]?.price || "");
    setDown(e.color[0]?.down || "");
    setImage(e.color[0]?.image || "");
    setPrice2(e.color[0]?.price2 || "")
    setSelectRoundPayment('');
    setSelectTimePayment(0);
    setErrorMessage(initStateMessaageError)
    setAmountPerRound('')
    // setAmountPerRoundDefault('')
  }

  const changeColor = (a: any) => {
    setSelectColor(a.colorName || "");
    setPrice(a.price || "");
    setDown(a.down || "");
    setImage(a.image || "");
    setPrice2(a.price2 || "");
    setTotalMonth(0);
    setSelectRoundPayment('');
    setSelectTimePayment(0);
    setErrorMessage(initStateMessaageError)
    setAmountPerRound('')
    // setAmountPerRoundDefault('')
  }

  useEffect(() => {
    setSelectRoundPayment('');
    setSelectTimePayment(0);
    setAmountPerRound('')
    // setAmountPerRoundDefault('')
    // setMinPayment(0)
    setTotalMonth(0)
    setDisableSave(true)
    setErrorMessage(initStateMessaageError)
  }, [typeAomDown]);

  return (
    <>
      {data && (
        <>
          <div className="text-center py-3" style={{ backgroundColor: colors.lightGray }}>
            <img height={150} width={150} src={image} onError={onImgError} />
          </div>
          <div className="px-3 py-3">
            <div className="pt-2 fw-bold font-18">{data[0]?.pname || ""}</div>
            <div >
              <div className="pt-2 pb-2 fw-bold">{"ประเภทการผ่อน"}</div>
              <div>
                <Row className="pt-2">
                  <Col>
                    <div className="d-flex justify-content-center align-items-center pt-2 " style={{ paddingRight: 20 }}>
                      {typeAomDown === 'INSTALLMENT_AND_USE' && (
                        <DotSelectMini sx={{ backgroundColor: colors.themeMainColor }} />
                      )}
                    </div>
                    <ButtonCustom
                      className="px-1"
                      variant="outlined"
                      active={typeAomDown === 'INSTALLMENT_AND_USE'}
                      mode="default"
                      btnStyle={{ width: "100%" }}
                      textButton="ผ่อนไปใช้ไป"
                      onClick={() => {
                        setTypeAomDown("INSTALLMENT_AND_USE");
                        const defaultOption = data[0]?.option[0];
                        if (defaultOption) {
                          setSelectedStorage(defaultOption.storage);
                          if (defaultOption.color && defaultOption.color[0]) {
                            setSelectColor(defaultOption.color[0].colorName);
                            setSelectColor(defaultOption.color[0].colorName);
                            setPrice(defaultOption?.color[0]?.price || "");
                            setDown(defaultOption?.color[0]?.down || "");
                            setPrice2(defaultOption?.color[0]?.price2 || "")
                          }
                        }
                      }}

                    />
                  </Col>
                  <Col>
                    <div className="d-flex justify-content-center align-items-center pt-2" style={{ paddingRight: 20 }}>
                      {typeAomDown === 'PAY_THEN_RECEIVE' && (
                        <DotSelectMini sx={{ backgroundColor: colors.themeMainColor }} />
                      )}
                    </div>
                    <ButtonCustom
                      className="px-1"
                      variant="outlined"
                      mode="default"
                      btnStyle={{ width: "100%" }}
                      textButton="ผ่อนจบรับของ"
                      active={typeAomDown === 'PAY_THEN_RECEIVE'}
                      onClick={() => {
                        setTypeAomDown("PAY_THEN_RECEIVE");
                        const defaultOption = data[0]?.option[0];
                        if (defaultOption) {
                          setSelectedStorage(defaultOption.storage);
                          if (defaultOption.color && defaultOption.color[0]) {
                            setSelectColor(defaultOption.color[0].colorName);
                            setPrice(defaultOption?.color[0]?.price || "");
                            setDown(defaultOption?.color[0]?.down || "");
                            setPrice2(defaultOption?.color[0]?.price2 || "")
                          }

                        }
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            {typeAomDown === "INSTALLMENT_AND_USE" ? (
              <>
                <div className="d-flex justify-content-start font-16 pt-3 fw-bold">
                  <div style={{ paddingRight: 6 }}>
                    <span>{"ราคา"}</span>
                  </div>
                  <div style={{ color: colors.themeMainColor }}>
                    <span>{`${numberFormat(price, 0, 0)} บาท` || "0 บาท"}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-start pt-2 font-14">
                  <div style={{ paddingRight: 6 }}>{"ได้รับสินค้าไปใช้เมื่อผ่อนถึง 20-65%"}</div>
                </div>
                <div className="pt-2 fw-bold">{"เลือกความจุ"}</div>
                <div className="pt-3" style={{ marginLeft: "-8px" }}>
                  <ToggleButtonGroup>
                    {_.map(data[0]?.option, (e, index) => {
                      return (
                        <div style={{ paddingLeft: 8 }} key={`${e.storage}-${index}`}>
                          {selectedStorage === e.storage && (
                            <DotSelectMini
                              sx={{ transform: "translate(36px , -13px)", backgroundColor: colors.themeMainColor }}
                            />
                          )}
                          <ButtonCustom
                            btnStyle={{ width: "100%" }}
                            active={selectedStorage === e.storage}
                            textButton={e.storage}
                            variant="outlined"
                            onClick={() => changeStorage(e)}
                          />
                        </div>
                      );
                    })}
                  </ToggleButtonGroup>
                </div>
                <div className="pt-2 fw-bold">{"เลือกสี"}</div>
                <div className="pt-2">
                  <div className="d-flex justify-content-start align-items-center">
                    {_.map(data[0]?.option, (val) => {
                      return (
                        val.storage === selectedStorage &&
                        _.map(val.color, (a) => {
                          return (
                            <div
                              className="pt-2"
                              style={{ marginLeft: "10px" }}
                              key={a.colorName || Math.random()}
                              onClick={() => changeColor(a)}
                            >
                              {selectColor === a.colorName && (
                                <>
                                  <DotSelect sx={{ backgroundColor: colors.themeMainColor }} />
                                  <DotSelectMini sx={{ backgroundColor: colors.themeMainColor }} />
                                </>
                              )}
                              {
                                a.colorName !== "None" && (
                                  <Dot sx={{ backgroundColor: a.colorCode || "" }} />
                                ) || (
                                  <SlashDot sx={{ backgroundColor: a.colorCode || "" }} />
                                )
                              }

                            </div>
                          );
                        })
                      );
                    })}
                  </div>
                </div>

              </>
            ) : (
              <>
                <div className="d-flex justify-content-start font-16 pt-3 fw-bold">
                  <div style={{ paddingRight: 6 }}>
                    <span>{"ราคา"}</span>
                  </div>
                  <div style={{ color: colors.themeMainColor }}>
                    <span>{`${numberFormat(price2, 0, 0)} บาท` || "0 บาท"}</span>
                  </div>
                </div>
                <div className="pt-2 fw-bold">{"เลือกความจุ"}</div>
                <div className="pt-3" style={{ marginLeft: "-8px" }}>
                  <ToggleButtonGroup>
                    {_.map(data[0]?.option, (e, index) => {
                      return (
                        <div style={{ paddingLeft: 8 }} key={`${e.storage}-${index}`}>
                          {selectedStorage === e.storage && (
                            <DotSelectMini
                              sx={{ transform: "translate(36px , -13px)", backgroundColor: colors.themeMainColor }}
                            />
                          )}
                          <ButtonCustom
                            btnStyle={{ width: "100%" }}
                            active={selectedStorage === e.storage}
                            textButton={e.storage}
                            variant="outlined"
                            onClick={() => changeStorage(e)}
                          />
                        </div>
                      );
                    })}
                  </ToggleButtonGroup>
                </div>
                <div className="pt-2 fw-bold">{"เลือกสี"}</div>
                <div className="pt-2">
                  <div className="d-flex justify-content-start align-items-center">
                    {_.map(data[0]?.option, (val) => {
                      return (
                        val.storage === selectedStorage &&
                        _.map(val.color, (a) => {
                          return (
                            <div
                              className="pt-2"
                              style={{ marginLeft: "10px" }}
                              key={a.colorName || Math.random()}
                              onClick={() => changeColor(a)}
                            >
                              {selectColor === a.colorName && (
                                <>
                                  <DotSelect sx={{ backgroundColor: colors.themeMainColor }} />
                                  <DotSelectMini sx={{ backgroundColor: colors.themeMainColor }} />
                                </>
                              )}
                              {
                                a.colorName !== "None" && (
                                  <Dot sx={{ backgroundColor: a.colorCode || "" }} />
                                ) || (
                                  <SlashDot sx={{ backgroundColor: a.colorCode || "" }} />
                                )
                              }

                            </div>
                          );
                        })
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            <Row className="pt-3">
              <Col
                lg={12}
                xs={12}
                sm={12}>
                <AutocompleteSelect
                  key={"roundPayment"}
                  size="medium"
                  options={dataRoundPayment}
                  value={_.find(dataRoundPayment, (val: any) => val.value === selectRoundPayment) || null}
                  getOptionLabel={(option: any) => option.label}
                  labelId="roundPayment"
                  onChange={(event, value, reason) => {
                    if (value) {
                      setSelectRoundPayment(value.value);
                      handelCalculate(0, value.value, true);
                      setErrorMessage(initStateMessaageError)
                    } else if (reason === 'clear') {
                      setSelectRoundPayment('');
                      setSelectTimePayment(0);
                      setAmountPerRound('')
                      // setAmountPerRoundDefault('')
                      setTotalMonth(0)
                      setErrorMessage(initStateMessaageError)
                    }
                  }}
                  required
                  heading="รอบการผ่อน"
                  helperText={errorMessage.ROUND}
                  placeholder="เลือกรอบการผ่อน"
                />
              </Col>
              <div className="pt-2">
                <Col
                  lg={12}
                  xs={12}
                  sm={12}>
                  <InputTextField
                    size="medium"
                    key={"amountPerRound"}
                    // value={disabledSave ? amountPerRound : amountPerRoundDefault}
                    value={amountPerRound}
                    disabled={selectRoundPayment === ''}
                    type="number"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    onchange={(event: any) => {
                      console.log(event.target.value)
                      setDisableSave(true)
                      if (event.target.value !== '') {
                        handelCalculate(Number(event.target.value), selectRoundPayment, false);
                      } else {
                        setAmountPerRound((event.target.value))
                      }
                    }}
                    // onBlur={(value: any) => {
                    //   // if (amountPerRound === '0') {
                    //   handelCalculate(Number(amountPerRound), selectRoundPayment);
                    //   // }
                    // }}
                    required
                    heading="ยอดผ่อนต่อรอบ (บาท) สามารถระบุยอดผ่อนเองได้"
                    helperText={errorMessage.ERROR}
                  />

                </Col>
              </div>

            </Row>
            <div className="pt-2">
              <InputTextField
                value={totalMonth}
                heading="ระยะเวลาผ่อน (เดือน)"
                disabled={true}
              />
            </div>
            <Row className="pt-4">
              <Col>
                <ButtonCustom
                  className={"px-1"}
                  mode={"default"}
                  btnStyle={{ width: "100%" }}
                  textButton={"ย้อนกลับ"}
                  onClick={() => {
                    props.onClickBack?.();
                  }}
                />
              </Col>
              <Col>
                <ButtonCustom
                  disabled={disabledSave}
                  className={"px-1"}
                  mode={"default"}
                  btnStyle={{ width: "100%" }}
                  textButton={"ยืนยัน"}
                  onClick={() => {
                    handelSubmit();
                  }}
                />
              </Col>
            </Row>

          </div>
        </>
      )}
    </>
  );
}
